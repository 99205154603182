// @flow
import toPairs from 'lodash/toPairs';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

export type ValueFileType = File | Blob;

export type ValueJSONable =
  | null
  | string
  | number
  | boolean
  | {}
  | $ReadOnlyArray<mixed>;

export type Value = void | ValueJSONable | ValueFileType;

export type Values = { [string]: Value };

export type ValueSanitized =
  | {
      name: string,
      value: ValueFileType,
      type: 'file',
    }
  | {
      name: string,
      value: ValueJSONable,
      type: 'jsonable',
    }
  | {
      name: string,
      value: void,
      type: 'undefined',
    };

const sanitizeValues = flow(
  toPairs,
  map(
    ([name, value]: [string, Value]): ValueSanitized => {
      if (value instanceof File || value instanceof Blob) {
        return { name, value: (value: ValueFileType), type: 'file' };
      }
      if (typeof value === 'undefined') {
        return { name, value, type: 'undefined' };
      }
      return { name, value: (value: ValueJSONable), type: 'jsonable' };
    },
  ),
  sortBy<ValueSanitized>(({ type }) => (type === 'file' ? 1 : -1)),
);

/**
 * Creates a new {@see FormData} instance and appends all the received values
 * and files.
 *
 * @param {Values} values
 *  Values to add in the form data. Should ethier be serializable using
 *  {@see JSON.stringify}, or File or Blob objects
 * @return {FormData}
 *  Form data containing the values and files
 */
export default function createFormData<V: Values>(values: V) {
  const formData = new FormData();
  sanitizeValues(values).forEach(sanitized => {
    switch (sanitized.type) {
      case 'jsonable':
        formData.append(
          sanitized.name,
          typeof sanitized.value === 'string'
            ? sanitized.value
            : JSON.stringify(sanitized.value),
        );
        break;
      case 'file':
        formData.append(sanitized.name, sanitized.value);
        break;
      default:
        break;
    }
  });
  return formData;
}
