// @flow
import * as React from 'react';

import useNodeDataSnackbarMessages, {
  type Data,
} from './useNodeDataSnackbarMessages';

export type Node = {
  data?: ?Data,
};

export default function useNodeSnackbarMessages(node: ?Node) {
  return useNodeDataSnackbarMessages(node?.data);
}
